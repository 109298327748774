require('Modernizr');

import Form from '@searchtrends/webform-processor';

try {
    Modernizr.on('webp', function (result) {
        if (result) {
            document.querySelector('body').classList.add('webp');
        } else {
            document.querySelector('body').classList.add('no-webp');
        }
    });
} catch (e) {
    document.querySelector('body').classList.add('no-webp');
}

let contactforms = {};
let forms = document.querySelectorAll('form[method="post"]:not(.no-ajax)');
forms.forEach(function (cf, index) {
    contactforms[`sendform_${index}`] = new Form(cf, index).onlyOnce();

    cf.addEventListener('submit', function (e) {
        e.preventDefault();

        contactforms[`sendform_${index}`].post(cf.action)
            .then(response => { console.log(response); });
    });
});

document.querySelector('form[name="suchen"]').addEventListener('submit', function (e) {
    e.preventDefault();

    let countries = {
        at: 'https://www.nbt-oesterreich.at',
        ch: 'https://www.nbt-schweiz.ch',
        de: 'https://www.nbt-deutschland.de',
    }

    if (document.querySelector('#searchCountry').value in countries) {
        let action = countries[document.querySelector('#searchCountry').value];
        action += '/search/1';

        document.querySelector('form[name="suchen"]').action = action;
        document.querySelector('form[name="suchen"]').submit();
    }
});
